import * as core from './gss-core';
import { getRelativeDate } from './gss-time';
import Highcharts from './gss-highcharts';
var highChartOptions = {
    credits: {
        text: 'Källa: SMHI',
        href: 'https://www.smhi.se'
    },
    chart: {
        type: 'spline',
        panning: true,
        zoomType: 'x'
    },
    title: {
        text: 'Molnprognos'
    },
    subtitle: {
        text: 'Prognos de närmaste 5 dagarna'
    },
    tooltip: {
        formatter: function () {
            let cloudType = {
                low_clouds: 'låga moln',
                medium_clouds: 'moln',
                high_clouds: 'höga moln'
            };
            return (
                this.y +
                '% ' +
                cloudType[this.key] +
                ' ' +
                getRelativeDate(new Date(this.x).toISOString())
            );
        }
    },
    xAxis: {
        type: 'datetime'
    },
    yAxis: {
        min: 0,
        minRange: 25,
        title: {
            text: 'Andel moln (%)'
        }
    },
    legend: {
        enabled: false
    }
};
/**
 * Creates zones for highchart
 * @param {Array<Object>} data
 * @returns {Array<Object>}
 */
function createZones(data) {
    let zones = [],
        currentZoneName,
        currentZoneEndPoint;
    const dashStyle = {
        low_clouds: 'Solid',
        medium_clouds: 'ShortDash',
        high_clouds: 'LongDash'
    };

    data.forEach(({ x, name }, index, arr) => {
        if (currentZoneName === name) {
            currentZoneEndPoint = x;
        } else if (currentZoneName) {
            zones.push({
                name: currentZoneName,
                value: currentZoneEndPoint,
                dashStyle: dashStyle[currentZoneName]
            });
            currentZoneName = name;
            currentZoneEndPoint = x;
        } else if (index === arr.length - 1) {
            //Last item
            zones.push({
                name,
                value: x,
                dashStyle: dashStyle[name]
            });
        } else {
            currentZoneName = name;
        }
    });
    return zones;
}
async function fetchCloudForecast(highChart) {
    let data = await core.fetchGraphData('/forecast/cloudcoverage/48');
    highChart.addSeries({
        data: data,
        color: 'white',
        zoneAxis: 'x',
        zones: createZones(data)
    });
}
export function showCloudDetails(highChartId) {
    let highChart = Highcharts.chart(highChartId, highChartOptions);
    fetchCloudForecast(highChart);
}
