/*global se */
const apiUrl = se.gss.conf.basePath + '/api';
var statOptions = {
        MIN: 'min',
        AVG: 'avg',
        MAX: 'max',
        VALUE: 'value'
    },
    cache = {};
function clone(object) {
    return JSON.parse(JSON.stringify(object));
}
/**
 * Function for fetching sensor data for a date
 * @param {String} url
 * @param {String} [turboMode = false] - see https://api.highcharts.com/highcharts/plotOptions.series.turboThreshold
 * @returns {Promise<Array<Object>>} - will return a Promise of an array of object {x: timestamp, y: value}
 */
async function fetchGraphData(url, turboMode = false) {
    if (cache[url]) {
        return clone(cache[url]);
    }
    try {
        let response = await fetch(apiUrl + url);
        let data = await response.json();
        if (turboMode) {
            cache[url] = data.map(({ date, value }) => {
                return [new Date(date).getTime(), value];
            });
        } else {
            cache[url] = data.map(({ date, value, type }) => {
                return {
                    x: new Date(date).getTime(),
                    y: value,
                    name: type
                };
            });
        }
    } catch {
        cache[url] = [];
    }
    return clone(cache[url]);
}
/**
 * Fetch statistical data
 *
 * @param {object} options
 * @param {string} options.url - url to endpoint
 * @param {enum} options.statOpt - enum
 * @param {boolean} options.drillDown - if drillDown should be used
 * @returns {Promise}
 */
async function fetchStatData(options) {
    if (cache[options.url] && cache[options.url][options.statOpt]) {
        return clone(cache[options.url][options.statOpt]);
    }
    try {
        let response = await fetch(apiUrl + options.url);
        let data = await response.json();
        cache[options.url] = cache[options.url] || {};
        cache[options.url][options.statOpt] = data.map((value) => {
            return {
                x: new Date(value.date).getTime(),
                y: value[options.statOpt],
                drilldown: options.drillDown
            };
        });
    } catch {
        cache[options.url][options.statOpt] = [];
    }
    return clone(cache[options.url][options.statOpt]);
}
/**
 * Fetch all statistical data
 * @param {object} options
 * @param {string} options.url - url to endpoint
 * @param {boolean} storeInCache - if data should be stored in client cache
 * @returns {Object|Array}
 */
async function fetchAllStatData({ url }, storeInCache = true) {
    if (cache[url]) {
        return clone(cache[url]);
    }
    try {
        let response = await fetch(apiUrl + url);
        let data = await response.json();
        if (storeInCache) {
            cache[url] = data;
        } else {
            return data;
        }
    } catch {
        cache[url] = [];
    }
    return clone(cache[url]);
}
export {
    fetchAllStatData as getJSON,
    fetchAllStatData,
    fetchStatData,
    fetchGraphData,
    statOptions
};
