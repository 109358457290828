import { DateTime } from 'luxon';
import {
    showAllData,
    showAllDataPerMonth,
    showDayData,
    showMonthData,
    showYearData,
    showTempDetails
} from './gss-temp';
import {
    initTempPanAndZoom,
    initTempPanAndZoomYearCompilation
} from './gss-nav';
const id = 'js-high-chart',
    now = DateTime.local();

export const routes = {
    '/temperatur/:year(\\d+)/:month/:day': temperatureDayDetails,
    '/temperatur/:year(\\d+)/:month': temperatureMonthDetails,
    '/temperatur/:year(\\d+)': temperatureYearDetails,
    '/temperatur': temperatureDetails,
    '/temperatur/:startYear-:endYear/:month': temperatureMonthCompilation,
    '/temperatur/:startYear-:endYear': temperatureYearCompilation
};
function temperatureDayDetails({ year, month, day }) {
    initTempPanAndZoom({ year, month, day });
    let requestedDate = DateTime.fromObject({ year, month, day });
    if (requestedDate.startOf('day') > now.startOf('day')) {
        let forecastHours =
            requestedDate.startOf('day').diff(now.startOf('day'), 'hours')
                .hours + 24;
        showTempDetails(id, forecastHours);
    } else {
        showDayData({
            period: year + '-' + month + '-' + day,
            id
        });
    }
}
function temperatureMonthDetails({ year, month }) {
    initTempPanAndZoom({ year, month });
    showMonthData({
        period: year + '-' + month,
        id
    });
}
function temperatureYearDetails({ year }) {
    initTempPanAndZoom({ year });
    showYearData({
        period: year,
        id
    });
}
function temperatureDetails() {
    initTempPanAndZoom(DateTime.local());
    showTempDetails(id);
}
function temperatureMonthCompilation({ startYear, endYear, month }) {
    initTempPanAndZoomYearCompilation({ startYear, endYear, month });
    showAllDataPerMonth({
        period: {
            startYear,
            endYear,
            month
        },
        id
    });
}
function temperatureYearCompilation({ startYear, endYear }) {
    initTempPanAndZoomYearCompilation({ startYear, endYear });
    showAllData({
        period: {
            startYear,
            endYear
        },
        id
    });
}
