const { DateTime } = require('luxon'),
    { getRelativeDate, convertMilliSecondsToDays } = require('./../gss-time');
function getDateTimeFromDateString(dateString) {
    let [year, month, day] = dateString.split('-');
    return DateTime.fromObject({ year: year, month: month, day: day });
}
function getMonthSubtitle(dateString) {
    return getDateTimeFromDateString(dateString)
        .setLocale('sv')
        .toFormat('LLLL yyyy')
        .toLowerCase();
}
function getYear(isoString) {
    return DateTime.fromISO(isoString).year;
}
function getMonth(isoString) {
    return DateTime.fromISO(isoString).setLocale('sv').toFormat('LLLL');
}
function getYearAndMonth(isoString) {
    return DateTime.fromISO(isoString).setLocale('sv').toFormat('yyyy LLLL');
}
function getTime(isoString) {
    return DateTime.fromISO(isoString).toLocaleString(DateTime.TIME_24_SIMPLE);
}
function getDayAndDate(isoString) {
    return DateTime.fromISO(isoString)
        .setLocale('sv')
        .toFormat('EEEE d MMMM')
        .toLowerCase();
}
/**
 * Convert date string to day date month year fromat
 * @param {String} dateString - on format YYYY-MM-DD
 * @returns {String}
 */
function getDateHuge(dateString) {
    return getDateTimeFromDateString(dateString)
        .setLocale('sv')
        .toLocaleString(DateTime.DATE_HUGE)
        .toLowerCase();
}
function getCurrentDateHuge() {
    return DateTime.local()
        .setLocale('sv')
        .toLocaleString(DateTime.DATE_HUGE)
        .toLowerCase();
}
function getCurrentYearAndMonth() {
    return DateTime.local().toFormat('yyyy-LL');
}
function getCurrentDate() {
    return DateTime.local().toLocaleString(DateTime.DATE_SHORT);
}
function isYesterday(dateString) {
    return getDateTimeFromDateString(dateString).hasSame(
        DateTime.local().minus({ days: 1 }),
        'day'
    );
}
module.exports = {
    convertMilliSecondsToDays,
    getMonthSubtitle,
    getCurrentDateHuge,
    getCurrentDate,
    getCurrentYearAndMonth,
    getTime,
    getDayAndDate,
    getDateHuge,
    getYearAndMonth,
    getMonth,
    getYear,
    getRelativeDate,
    isYesterday
};
