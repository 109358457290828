function getWindSector(degrees) {
    var sectors = ['N', 'NÖ', 'Ö', 'SÖ', 'S', 'SV', 'V', 'NV'],
        sectorAngle = 360 / sectors.length;
    return sectors[
        Math.floor((degrees + sectorAngle / 2) / sectorAngle) % sectors.length
    ];
}

module.exports = {
    getWindDirection: getWindSector
};
