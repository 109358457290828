const { DateTime, Settings } = require('luxon');
Settings.defaultLocale = 'sv-SE';
/*
function getDateTimeFromDateString(dateString) {
    let [year, month, day] = dateString.split('-');
    return DateTime.fromObject({ year: year, month: month, day: day });
}
function getMonthSubtitle(dateString) {
    return getDateTimeFromDateString(dateString)
        .setLocale('sv')
        .toFormat('LLLL yyyy')
        .toLowerCase();
}
function getYear(isoString) {
    return DateTime.fromISO(isoString).year;
}
function getMonth(isoString) {
    return DateTime.fromISO(isoString).toFormat('LLLL');
}
function getTime(isoString) {
    return DateTime.fromISO(isoString).toLocaleString(DateTime.TIME_24_SIMPLE);
}
function getDayAndDate(isoString) {
    return DateTime.fromISO(isoString)
        .toFormat('EEEE d MMMM')
        .toLowerCase();
}
function getDateHuge(dateString) {
    return getDateTimeFromDateString(dateString)
        .toLocaleString(DateTime.DATE_HUGE)
        .toLowerCase();
}
function getCurrentDateHuge() {
    return DateTime.local()
        .toLocaleString(DateTime.DATE_HUGE)
        .toLowerCase();
}
function getCurrentYearAndMonth() {
    return DateTime.local().toFormat('yyyy-LL');
}
function getCurrentDate() {
    return DateTime.local().toLocaleString(DateTime.DATE_SHORT);
}
function isYesterday(dateString) {
    return getDateTimeFromDateString(dateString).hasSame(
        DateTime.local().minus({ days: 1 }),
        'day'
    );
}*/
function getRelativeDate(isoString) {
    const now = DateTime.local(),
        yesterDay = now.minus({ days: 1 }),
        dayBeforeYesterDay = now.minus({ days: 2 }),
        tomorrow = now.plus({ days: 1 }),
        dayAfterTomorrow = now.plus({ days: 2 }),
        dateTime = DateTime.fromISO(isoString);
    let relativeDayString;
    if (dateTime.isValid) {
        if (dayBeforeYesterDay.hasSame(dateTime, 'day')) {
            relativeDayString = 'Förrgår';
        } else if (yesterDay.hasSame(dateTime, 'day')) {
            relativeDayString = 'Igår';
        } else if (now.hasSame(dateTime, 'day')) {
            relativeDayString = 'Idag';
        } else if (tomorrow.hasSame(dateTime, 'day')) {
            relativeDayString = 'Imorgon';
        } else if (dayAfterTomorrow.hasSame(dateTime, 'day')) {
            relativeDayString = 'Övermorgon';
        } else {
            return dateTime.toLocaleString(DateTime.DATETIME_MED);
        }
        return `${relativeDayString} ${dateTime.toLocaleString(
            DateTime.TIME_24_SIMPLE
        )}`;
    } else {
        return '';
    }
}
function convertMilliSecondsToDays(milliSeconds) {
    return Math.floor(milliSeconds / (1000 * 60 * 60 * 24));
}
function now() {
    return DateTime.local();
}
const minDate = DateTime.fromISO('2015-03-01T22:00:00.000+02:00');
function supportedDate(requestedYear, requestedMonth, requestedDay) {
    let requestedDate = DateTime.fromObject({
        year: requestedYear,
        month:
            ~~requestedMonth ||
            (+requestedYear === minDate.year ? minDate.month : undefined),
        day:
            ~~requestedDay ||
            (+requestedYear === minDate.year ? minDate.day : undefined)
    });
    return (
        requestedDate.isValid &&
        requestedDate.toISODate() >= minDate.toISODate() &&
        requestedDate < now().plus({ days: 10 })
    );
}

module.exports = {
    getRelativeDate,
    convertMilliSecondsToDays,
    supportedDate
};
