import query from './query';
import { showOverview } from './gss-overview';
import * as hum from './gss-hum';
import { showThunderDetails } from './gss-thunder';
import { showCloudDetails } from './gss-cloud';
import { hidePanAndZoom } from './gss-nav';
const routes = {
    '/oversikt': overview,
    '/luftfuktighet': humidityDetails,
    '/aska': thunderDetails,
    '/moln': cloudDetails
};
function overview() {
    let overviewView = query('.js-overview-view'),
        overviewNav = query('.js-overview'),
        detailView = query('.js-detail-view'),
        statView = query('.js-stat'); //,
    //graph = $('.js-high-chart');
    hidePanAndZoom();
    if (!overviewNav.hasClass('active-nav')) {
        //Empty graph
        //graph.empty();
        // Check if navigation is triggered
        // Change highlighted item
        query('.active-nav').removeClass('active-nav');
        // User has used navigation
        overviewNav.addClass('active-nav');
        if (!detailView.hasClass('hidden')) {
            detailView.addClass('hidden');
        }
        if (!statView.hasClass('hidden')) {
            statView.addClass('hidden');
        }
        overviewView.removeClass('hidden');
        showOverview();
    }
}
function humidityDetails() {
    hidePanAndZoom();
    hum.showHumDetails('js-high-chart');
}
function thunderDetails() {
    hidePanAndZoom();
    showThunderDetails('js-high-chart');
}
function cloudDetails() {
    hidePanAndZoom();
    showCloudDetails('js-high-chart');
}
export { routes };
