import Chart from 'highcharts/es-modules/Core/Chart/Chart';
import 'highcharts/es-modules/Series/Line/LineSeries';
import Spline from 'highcharts/es-modules/Series/Spline/SplineSeries';
import ColumnSeries from 'highcharts/es-modules/Series/Column/ColumnSeries';
import ColumnSeriesDataLabel from 'highcharts/es-modules/Series/Column/ColumnDataLabel';
import Axis from 'highcharts/es-modules/Core/Axis/Axis';
import OrdinalAxis from 'highcharts/es-modules/Core/Axis/OrdinalAxis';
import DateTimeAxis from 'highcharts/es-modules/Core/Axis/DateTimeAxis';
import 'highcharts/es-modules/Extensions/NoDataToDisplay';
import './gss-highcharts-language-se';
import './gss-highcharts-dark-theme';
ColumnSeriesDataLabel.compose(ColumnSeries);
OrdinalAxis.compose(Axis, Spline, Chart);
DateTimeAxis.compose(Axis);
export default Chart;
