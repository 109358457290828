import query from '../client-script/query';
import { getWindSector } from './gss-wind';
import { getRelativeDate, convertMilliSecondsToDays } from './gss-time';
import { getJSON } from './gss-core';
function updateData(selector, { value, timestamp }) {
    query(`.js-${selector}`).text(value);
    query(`.js-${selector}-time`).text(getRelativeDate(timestamp));
}
async function showOverview() {
    let data = await getJSON({ url: '/currentsensorreadings' }, false);
    Object.keys(data).forEach((key) => {
        switch (key) {
            case 'currentTemp':
                updateData('current-temp', data.currentTemp);
                break;
            case 'maxTemp24h':
                updateData('max-temp-24', data.maxTemp24h);
                break;
            case 'minTemp24h':
                updateData('min-temp-24', data.minTemp24h);
                break;
            case 'currentHum':
                updateData('current-hum', data.currentHum);
                break;
            case 'maxHum24h':
                updateData('max-hum-24', data.maxHum24h);
                break;
            case 'minHum24h':
                updateData('min-hum-24', data.minHum24h);
                break;
            case 'radarRainLast24h':
                if (data.radarRainLast24h.value) {
                    updateData('rain-24', data.radarRainLast24h);
                }
                break;
            case 'lastRadarRainTime':
                if (!data.radarRainLast24h.value) {
                    query('.js-last-rain').text(
                        convertMilliSecondsToDays(data[key].value)
                    );
                }
                break;
            case 'nextRainTime':
                if (data.nextRainTime.timestamp) {
                    query('.js-next-rain').text(
                        getRelativeDate(data.nextRainTime.timestamp)
                    );
                } else {
                    query('.js-next-rain').text(
                        'Inget regn de närmaste 10 dagarna'
                    );
                }
                break;
            case 'currentWindSpeed':
                updateData('current-wind-speed', data.currentWindSpeed);
                break;
            case 'currentWindDirection':
                query('.js-current-wind-direction').text(
                    getWindSector(parseInt(data.currentWindDirection.value, 10))
                );
                break;
            case 'thunderProb':
                updateData('thunder-prognosis', data.thunderProb);
                break;
        }
    });
}
export { showOverview };
