import DefaultOptions from 'highcharts/es-modules/Core/Defaults';
const options = {
    time: {
        timezone: 'Europe/Oslo',
        useUTC: false
    },
    lang: {
        decimalPoint: ',',
        months: [
            'januari',
            'februari',
            'mars',
            'april',
            'maj',
            'juni',
            'juli',
            'augusti',
            'september',
            'oktober',
            'november',
            'december'
        ],
        shortMonths: [
            'jan',
            'feb',
            'mar',
            'apr',
            'maj',
            'jun',
            'jul',
            'aug',
            'sep',
            'okt',
            'nov',
            'dec'
        ],
        weekdays: [
            'söndag',
            'måndag',
            'tisdag',
            'onsdag',
            'torsdag',
            'fredag',
            'lördag'
        ],
        noData: 'Det finns ingen data att visa'
    }
};
DefaultOptions.setOptions(options);
