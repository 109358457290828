import * as core from './gss-core';
import { getCurrentDateHuge, getTime } from './gss-time';
import Highcharts from './gss-highcharts';

var highChartOptions = {
    credits: {
        enabled: false
    },
    chart: {
        type: 'spline',
        panning: true,
        zoomType: 'x'
    },
    title: {
        text: 'Luftfuktighet'
    },
    subtitle: {
        text: getCurrentDateHuge()
    },
    tooltip: {
        formatter: function () {
            return this.y + '% ' + getTime(new Date(this.x).toISOString());
        }
    },
    xAxis: {
        type: 'datetime'
    },
    yAxis: {
        min: 0,
        max: 100,
        title: {
            text: 'Luftfuktighet (%)'
        }
    },
    legend: {
        enabled: false
    }
};
async function fetchLast24hHumData(highChart) {
    let data = await core.fetchGraphData('/humidity/24h');
    highChart.addSeries({
        data
    });
}
function showHumDetails(highChartId) {
    let highChart = Highcharts.chart(highChartId, highChartOptions);
    fetchLast24hHumData(highChart);
}
export { showHumDetails };
