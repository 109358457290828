import * as core from './gss-core';
import { getRelativeDate } from './gss-time';
import Highcharts from './gss-highcharts';
var highChartOptions = {
    credits: {
        text: 'Källa: SMHI',
        href: 'https://www.smhi.se'
    },
    chart: {
        type: 'spline',
        panning: true,
        zoomType: 'x'
    },
    title: {
        text: 'Chans för åska'
    },
    subtitle: {
        text: 'Prognos de närmaste 5 dagarna'
    },
    tooltip: {
        formatter: function () {
            return (
                this.y + '% ' + getRelativeDate(new Date(this.x).toISOString())
            );
        }
    },
    xAxis: {
        type: 'datetime'
    },
    yAxis: {
        min: 0,
        minRange: 25,
        title: {
            text: 'Åskchans (%)'
        }
    },
    legend: {
        enabled: false
    }
};
async function fetchThunderForecast(highChart) {
    let data = await core.fetchGraphData('/forecast/thunder/120');
    highChart.addSeries({
        data: data,
        color: 'red'
    });
}
export function showThunderDetails(highChartId) {
    let highChart = Highcharts.chart(highChartId, highChartOptions);
    fetchThunderForecast(highChart);
}
