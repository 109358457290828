import * as core from './gss-core';
import {
    getCurrentDateHuge,
    getDateHuge,
    getMonth,
    getMonthSubtitle,
    getYearAndMonth,
    getTime,
    getDayAndDate,
    getRelativeDate
} from './gss-time';
import Highcharts from './gss-highcharts';
import { DateTime } from 'luxon';
const forecastDays = [2, 3, 4, 5, 6, 7, 8, 9, 10],
    now = new Date().getTime();
let highChartOptions = {
    credits: {
        text: 'Prognos från SMHI',
        href: 'https://www.smhi.se'
    },
    chart: {
        type: 'spline',
        panning: true,
        zoomType: 'x'
    },
    title: {
        text: 'Temperatur'
    },
    subtitle: {
        text: getCurrentDateHuge()
    },
    tooltip: {
        formatter() {
            if (this.x > now + 24 * 60 * 60 * 1000) {
                return (
                    this.y +
                    '° ' +
                    getRelativeDate(new Date(this.x).toISOString())
                );
            } else {
                return this.y + '° ' + getTime(new Date(this.x).toISOString());
            }
        }
    },
    xAxis: {
        type: 'datetime'
    },
    yAxis: {
        minRange: 20,
        title: {
            text: 'Temperatur (°C)'
        }
    },
    plotOptions: {
        series: {
            marker: {
                enabled: false
            }
        }
    },
    legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'vertical',
        x: 0,
        y: 0
    }
};
function findMinMax(forecast) {
    let min = forecast[0],
        max = forecast[0];
    forecast.forEach(({ x, y }) => {
        if (y < min.y) {
            min = { x, y };
        } else if (y > max.y) {
            max = { x, y };
        }
    });
    return { min, max };
}
export async function showTempDetails(highChartId, forecastPeriod = 24) {
    let highChart = Highcharts.chart(highChartId, highChartOptions),
        observation24h = await core.fetchGraphData('/temperature/24h'),
        forecast = await core.fetchGraphData(
            '/forecast/temperature/' + forecastPeriod
        );
    highChart.addSeries(
        {
            data: observation24h,
            name: 'Observation',
            index: 1
        },
        false
    );
    if (forecastPeriod > forecastDays[1] * 24) {
        let splitIndex = forecastDays.map((day) => {
                return forecast.findIndex((item, index, [{ x }]) => {
                    return item.x > x + day * 24 * 60 * 60 * 1000;
                });
            }),
            minSeries = [forecast[splitIndex[0] - 1]],
            maxSeries = [forecast[splitIndex[0] - 1]];
        for (let index = 1; index < splitIndex.length; index++) {
            if (splitIndex[index] > 0) {
                let dayForecast = forecast.slice(
                        splitIndex[index - 1],
                        splitIndex[index]
                    ),
                    { min, max } = findMinMax(dayForecast);
                minSeries.push(min);
                maxSeries.push(max);
            } else {
                break;
            }
        }
        highChart.addSeries(
            {
                color: 'red',
                data: forecast.splice(0, splitIndex[0]),
                name: 'Prognos',
                index: 2
            },
            false
        );
        highChart.addSeries(
            {
                color: 'red',
                data: maxSeries,
                name: 'Prognos MAX',
                index: 3
            },
            false
        );
        highChart.addSeries(
            {
                color: 'blue',
                data: minSeries,
                name: 'Prognos MIN',
                index: 4
            },
            true
        );
    } else {
        highChart.addSeries(
            {
                color: 'red',
                data: forecast,
                name: 'Prognos',
                index: 2
            },
            true
        );
    }
}
export async function showDayData(options) {
    let highChart = Highcharts.chart(
            options.id,
            Object.assign({}, highChartOptions, {
                subtitle: {
                    text: getDateHuge(options.period)
                }
            })
        ),
        data = await core.fetchGraphData(
            '/temperature/' + options.period.replace(/-/g, '/'),
            true
        );
    highChart.addSeries({
        data,
        name: 'Observation',
        index: 1
    });
}
function addMinMaxAvgToChart(min, max, avg, chart) {
    chart.addSeries(
        {
            color: 'red',
            data: max,
            name: 'Max',
            index: 1
        },
        false
    );
    chart.addSeries(
        {
            color: 'green',
            data: avg,
            name: 'Medel',
            index: 2
        },
        false
    );
    chart.addSeries(
        {
            color: 'blue',
            data: min,
            name: 'Min',
            index: 3
        },
        true
    );
}
async function fetchStatData(period, chart) {
    let data = await core.fetchAllStatData({
            url: '/temperature/' + period.replace(/-/g, '/')
        }),
        min = [],
        avg = [],
        max = [];
    data.forEach((d) => {
        let timestamp = new Date(d.date).getTime();
        min.push({
            x: timestamp,
            y: d.min
        });
        avg.push({
            x: timestamp,
            y: d.avg
        });
        max.push({
            x: timestamp,
            y: d.max
        });
    });
    addMinMaxAvgToChart(min, max, avg, chart);
}

/**
 * Show temp data for a specific month
 *
 * @param {object} options
 * @param {string} options.id - id for the chart
 * @param {string} options.period - Date with format YYYY-MM
 */
export function showMonthData(options) {
    let highChart = Highcharts.chart(
        options.id,
        Object.assign({}, highChartOptions, {
            credits: {
                enabled: false
            },
            subtitle: {
                text: getMonthSubtitle(options.period)
            },
            tooltip: {
                formatter: function () {
                    return (
                        this.y +
                        '° ' +
                        getDayAndDate(new Date(this.x).toISOString())
                    );
                }
            }
        })
    );
    fetchStatData(options.period, highChart);
}
/**
 * Show temp data for a specific year
 *
 * @param {object} options
 * @param {string} options.id - id for the chart
 * @param {string} options.period - Date with format YYYY
 */
export function showYearData(options) {
    let highChart = Highcharts.chart(
        options.id,
        Object.assign({}, highChartOptions, {
            credits: {
                enabled: false
            },
            subtitle: {
                text: options.period
            },
            tooltip: {
                formatter: function () {
                    return (
                        this.y + '° ' + getMonth(new Date(this.x).toISOString())
                    );
                }
            }
        })
    );
    fetchStatData(options.period, highChart);
}
export async function showAllDataPerMonth({ period, id }) {
    let { startYear, endYear, month } = period,
        monthName = DateTime.fromObject({
            year: startYear,
            month: month,
            day: 1
        })
            .setLocale('sv')
            .toFormat('LLLL'),
        maxData = [],
        minData = [],
        avgData = [],
        yearOptions = Object.assign({}, highChartOptions, {
            subtitle: {
                text: monthName
            }
        }),
        chart = Highcharts.chart(id, yearOptions);

    for (let year = startYear; year <= endYear; year++) {
        let data = await core.getJSON({
                url: '/temperature/' + year
            }),
            monthData;
        if (data.length === 12) {
            monthData = data[parseInt(month) - 1];
        } else {
            monthData = data.find(({ date }) => {
                let monthOfValue = date.split('-')[1];
                return monthOfValue === month;
            });
        }
        if (monthData) {
            let { date, min, max, avg } = monthData,
                timestamp = new Date(date).getTime();
            maxData.push({
                x: timestamp,
                y: max
            });
            avgData.push({
                x: timestamp,
                y: avg
            });
            minData.push({
                x: timestamp,
                y: min
            });
        }
    }
    addMinMaxAvgToChart(minData, maxData, avgData, chart);
}
/**
 * Showing year compilation data for min, max and average
 * @param {Object} param
 * @param {object} period
 * @param {number} period.startYear - Start year of year compilation
 * @param {number} period.endYear - End year of year compilation
 * @param {string} id - id for the chart
 */
export async function showAllData({ period, id }) {
    let yearOptions = Object.assign({}, highChartOptions, {
            subtitle: {
                text: 'Årsdata'
            },
            tooltip: {
                formatter() {
                    return (
                        this.y +
                        '° ' +
                        getYearAndMonth(new Date(this.x).toISOString())
                    );
                }
            }
        }),
        chart = Highcharts.chart(id, yearOptions),
        maxData = [],
        avgData = [],
        minData = [];
    for (let year = period.startYear; year <= period.endYear; year++) {
        let data = await core.getJSON({
            url: '/temperature/' + year
        });
        let yearMax = [],
            yearAvg = [],
            yearMin = [];
        data.forEach((point) => {
            yearMax.push(point.max);
            yearAvg.push(point.avg);
            yearMin.push(point.min);
        });
        let maxOfYear = Math.max(...yearMax),
            minOfYear = Math.min(...yearMin),
            avgOfYear =
                parseInt(
                    (10 * yearAvg.reduce((acc, curr) => acc + curr, 0)) /
                        yearAvg.length
                ) / 10,
            dateForMaxOfYear = data.find(
                (point) => point.max === maxOfYear
            ).date,
            dateForMinOfYear = data.find(
                (point) => point.min === minOfYear
            ).date,
            dateForAvgOfYear = data[Math.floor(data.length / 2)].date;
        maxData.push({
            x: new Date(dateForMaxOfYear).getTime(),
            y: maxOfYear
        });
        avgData.push({
            x: new Date(dateForAvgOfYear).getTime(),
            y: avgOfYear
        });
        minData.push({
            x: new Date(dateForMinOfYear).getTime(),
            y: minOfYear
        });
    }
    addMinMaxAvgToChart(minData, maxData, avgData, chart);
}
