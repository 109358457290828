import { DateTime } from 'luxon';
import { fetchGraphData, fetchAllStatData } from './gss-core';
import {
    getCurrentDateHuge,
    getDateHuge,
    getMonth,
    getYearAndMonth,
    getTime,
    getDayAndDate
} from './gss-time';
import Highcharts from './gss-highcharts';
import { getWindDirection } from './../gss-wind';
var highChartOptions = {
    credits: {
        text: 'Källa: SMHI',
        href: 'https://www.smhi.se'
    },
    chart: {
        type: 'spline',
        panning: true,
        zoomType: 'x'
    },
    title: {
        text: 'Vind'
    },

    subtitle: {
        text: getCurrentDateHuge()
    },
    tooltip: {
        formatter: function () {
            return (
                this.y +
                ' m/s ' +
                this.key +
                ' ' +
                getTime(new Date(this.x).toISOString())
            );
        }
    },
    xAxis: {
        type: 'datetime'
    },
    yAxis: {
        allowDecimals: false,
        min: 0,
        minRange: 10,
        title: {
            text: 'Vind (m/s)'
        }
    },
    plotOptions: {
        series: {
            marker: {
                enabled: false
            }
        }
    },
    legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'vertical',
        x: 0,
        y: 0
    }
};
function addWindDirection(gustData, speedData, direction) {
    direction.forEach(({ x, y }) => {
        let windDirection = getWindDirection(y);
        let gust = gustData.find((g) => g.x === x);
        let speed = speedData.find((s) => s.x === x);
        if (gust) {
            gust.name = windDirection;
        }
        if (speed) {
            speed.name = windDirection;
        }
    });
    return { gustData, speedData };
}
async function fetchStatData(sensor, year, month) {
    let url = `/${sensor}/${year}${month ? '/' + month : ''}`,
        min = [],
        avg = [],
        max = [],
        data = await fetchAllStatData({ url });
    data.forEach((d) => {
        let timestamp = new Date(d.date).getTime();
        min.push({
            x: timestamp,
            y: d.min
        });
        avg.push({
            x: timestamp,
            y: d.avg
        });
        max.push({
            x: timestamp,
            y: d.max
        });
    });
    return { min, avg, max };
}
/**
 * Fetch aggregated wind speed, gust and direction for a year
 * @param {Number} year
 * @returns {Promise<Object>}
 */
async function fetchWindDataForYear(year) {
    let [windGust, windSpeed, windDirection] = await Promise.all(
            ['gust', 'speed', 'direction'].map((sensor) => {
                return fetchStatData(`wind${sensor}`, year);
            })
        ),
        { gustData, speedData } = addWindDirection(
            windGust.max,
            windSpeed.avg,
            windDirection.avg
        ),
        windDirectionData = windDirection.avg;
    return { gustData, speedData, windDirectionData };
}
function getMonthData(data, month) {
    if (data.length === 12) {
        return data[parseInt(month) - 1];
    } else {
        return data.find(({ x }) => {
            let monthOfValue = new Date(x).toLocaleDateString().split('-')[1];
            return monthOfValue === month;
        });
    }
}
async function showWindObservationForAllTimePerMonth(
    id,
    startYear,
    endYear,
    month
) {
    let totalGustData = [],
        totalSpeedData = [],
        monthName = DateTime.fromObject({
            year: startYear,
            month: month,
            day: 1
        })
            .setLocale('sv')
            .toFormat('LLLL'),
        highChart = Highcharts.chart(
            id,
            Object.assign({}, highChartOptions, {
                subtitle: {
                    text: monthName
                },
                tooltip: {
                    formatter: function () {
                        return (
                            this.y +
                            ' m/s ' +
                            this.key +
                            ' ' +
                            getYearAndMonth(new Date(this.x).toISOString())
                        );
                    }
                }
            })
        );
    for (let year = startYear; year <= endYear; year++) {
        let { gustData, speedData } = await fetchWindDataForYear(year),
            monthData;
        if (gustData.length) {
            monthData = getMonthData(gustData, month);
            if (monthData) {
                totalGustData.push(monthData);
            }
        }
        monthData = getMonthData(speedData, month);
        if (monthData) {
            totalSpeedData.push(monthData);
        }
    }
    highChart.addSeries(
        {
            data: totalSpeedData,
            name: 'Observation - medelvind',
            index: 1
        },
        false
    );
    highChart.addSeries({
        color: '#2b908f',
        dashStyle: 'Dot',
        data: totalGustData,
        name: 'Observation - Max byvind',
        index: 3
    });
}
async function showWindObservationForAllTime(id, startYear, endYear) {
    let totalGustData = [],
        totalSpeedData = [],
        highChart = Highcharts.chart(
            id,
            Object.assign({}, highChartOptions, {
                subtitle: {
                    text: `${startYear} - ${endYear}`
                },
                tooltip: {
                    formatter: function () {
                        return `${this.y} m/s ${this.key} ${getYearAndMonth(
                            new Date(this.x).toISOString()
                        )}`;
                    }
                }
            })
        );
    for (let year = startYear; year <= endYear; year++) {
        let { gustData, speedData } = await fetchWindDataForYear(year),
            maxGustData = gustData.reduce(
                (prev, curr) => {
                    return curr.y > prev.y ? curr : prev;
                },
                {
                    y: 0
                }
            ),
            maxSpeedData = speedData.reduce(
                (prev, curr) => {
                    return curr.y > prev.y ? curr : prev;
                },
                {
                    y: 0
                }
            );
        if (maxGustData.x) {
            totalGustData.push(maxGustData);
        }
        totalSpeedData.push(maxSpeedData);
    }
    highChart.addSeries(
        {
            data: totalSpeedData,
            name: 'Observation - medelvind',
            index: 1
        },
        false
    );
    highChart.addSeries({
        color: '#2b908f',
        dashStyle: 'Dot',
        data: totalGustData,
        name: 'Observation - Max byvind',
        index: 3
    });
}
async function showWindObservationForYear(id, year) {
    let { gustData, speedData } = await fetchWindDataForYear(year),
        highChart = Highcharts.chart(
            id,
            Object.assign({}, highChartOptions, {
                subtitle: {
                    text: `${year}`
                },
                tooltip: {
                    formatter: function () {
                        return (
                            this.y +
                            ' m/s ' +
                            this.key +
                            ' ' +
                            getMonth(new Date(this.x).toISOString())
                        );
                    }
                }
            })
        );
    if (gustData.length) {
        highChart.addSeries(
            {
                color: '#2b908f',
                dashStyle: 'Dot',
                data: gustData,
                name: 'Observation - byvind',
                index: 3
            },
            false
        );
    }
    highChart.addSeries({
        data: speedData,
        name: 'Observation - medelvind',
        index: 1
    });
}

async function showWindObservationForMonth(id, year, month) {
    let [windGust, windSpeed, windDirection] = await Promise.all(
            ['gust', 'speed', 'direction'].map((sensor) => {
                return fetchStatData(`wind${sensor}`, year, month);
            })
        ),
        { gustData, speedData } = addWindDirection(
            windGust.max,
            windSpeed.avg,
            windDirection.avg
        ),
        highChart = Highcharts.chart(
            id,
            Object.assign({}, highChartOptions, {
                subtitle: {
                    text: `${getMonth(year + month + '15')} ${year}`
                },
                tooltip: {
                    formatter: function () {
                        return (
                            this.y +
                            ' m/s ' +
                            this.key +
                            ' ' +
                            getDayAndDate(new Date(this.x).toISOString())
                        );
                    }
                }
            })
        );
    if (gustData.length) {
        highChart.addSeries(
            {
                color: '#2b908f',
                dashStyle: 'Dot',
                data: gustData,
                name: 'Observation - byvind',
                index: 3
            },
            false
        );
    }
    highChart.addSeries({
        data: speedData,
        name: 'Observation - medelvind',
        index: 1
    });
}
async function showWindObservationForDay(id, year, month, day) {
    let [windGust, windSpeed, windDirection] = await Promise.all(
            ['gust', 'speed', 'direction'].map(async (sensor) => {
                try {
                    return fetchGraphData(
                        `/wind${sensor}/${year}/${month}/${day}`
                    );
                } catch (e) {
                    return { min: [], max: [], avg: [] };
                }
            })
        ),
        { gustData, speedData } = addWindDirection(
            windGust,
            windSpeed,
            windDirection
        ),
        highChart = Highcharts.chart(
            id,
            Object.assign({}, highChartOptions, {
                subtitle: {
                    text: getDateHuge(`${year}-${month}-${day}`)
                }
            })
        );
    highChart.addSeries(
        {
            data: speedData,
            name: 'Observation - medelvind',
            index: 1
        },
        false
    );
    highChart.addSeries({
        color: '#2b908f',
        dashStyle: 'Dot',
        data: gustData,
        name: 'Observation - byvind',
        index: 3
    });
}
async function showWindObservation(highChart) {
    let [windGust, windSpeed, windDirection] = await Promise.all(
            ['gust', 'speed', 'direction'].map((sensor) => {
                return fetchGraphData(`/wind${sensor}/24h`);
            })
        ),
        { gustData, speedData } = addWindDirection(
            windGust,
            windSpeed,
            windDirection
        );
    highChart.addSeries(
        {
            data: speedData,
            name: 'Observation - medelvind',
            index: 1
        },
        false
    );
    highChart.addSeries({
        color: '#2b908f',
        dashStyle: 'Dot',
        data: gustData,
        name: 'Observation - byvind',
        index: 3
    });
}
async function showWindForecast(highChart, forecastPeriod) {
    let [windGust, windSpeed, windDirection] = await Promise.all(
            ['gust', 'speed', 'direction'].map((sensor) => {
                return fetchGraphData(
                    `/forecast/wind${sensor}/${forecastPeriod}`
                );
            })
        ),
        { gustData, speedData } = addWindDirection(
            windGust,
            windSpeed,
            windDirection
        );
    let forecastDate = DateTime.local().plus({ hour: forecastPeriod });
    highChart.setTitle(
        highChartOptions.title,
        {
            text: `${DateTime.local().toISODate()} - ${forecastDate.toISODate()}`
        },
        false
    );
    highChart.addSeries(
        {
            color: 'red',
            data: speedData,
            name: 'Prognos - medelvind',
            index: 2
        },
        false,
        false
    );
    highChart.addSeries(
        {
            color: 'red',
            dashStyle: 'Dot',
            data: gustData,
            name: 'Prognos - byvind',
            index: 4
        },
        true,
        false
    );
}
function showWindDetails(highChartId, forecastPeriod = 24) {
    let highChart = Highcharts.chart(highChartId, highChartOptions);
    showWindObservation(highChart);
    showWindForecast(highChart, forecastPeriod);
}
export {
    showWindDetails,
    showWindObservationForDay,
    showWindObservationForMonth,
    showWindObservationForYear,
    showWindObservationForAllTime,
    showWindObservationForAllTimePerMonth,
    getWindDirection as getWindSector
};
