class $nodeList {
    #notValid = false;
    constructor(nodeList) {
        this.nodeList = nodeList;
    }
    #changeClasses(className, method) {
        for (let index = 0; index < this.nodeList.length; index++) {
            const element = this.nodeList[index];
            element.classList[method](className);
        }
        return this;
    }
    hasClass(className) {
        if (this.#notValid) {
            return false;
        }
        if (this.nodeList.length === 0) {
            return false;
        } else if (this.nodeList.length === 1) {
            let classList = this.nodeList[0].classList;
            if (classList.contains(className)) {
                return this;
            } else {
                return false;
            }
        } else {
            throw new Error(
                `hasClass is expecting exactly 1 node, got ${this.nodeList.length} nodes`
            );
        }
    }
    removeClass(className) {
        return this.#changeClasses(className, 'remove');
    }
    addClass(className) {
        return this.#changeClasses(className, 'add');
    }
    toggleClass(className) {
        return this.#changeClasses(className, 'toggle');
    }
    attr(name, value) {
        for (let index = 0; index < this.nodeList.length; index++) {
            const element = this.nodeList[index];
            element.setAttribute(name, value);
        }
        return this;
    }
    removeAttr(name) {
        for (let index = 0; index < this.nodeList.length; index++) {
            const element = this.nodeList[index];
            element.removeAttribute(name);
        }
        return this;
    }
    on(event, fn) {
        for (let index = 0; index < this.nodeList.length; index++) {
            const element = this.nodeList[index];
            element.addEventListener(event, fn);
        }
        return this;
    }
    text(text) {
        for (let index = 0; index < this.nodeList.length; index++) {
            const element = this.nodeList[index];
            element.textContent = text;
        }
        return this;
    }
}

export default function query(selector) {
    let elements = document.querySelectorAll(selector);
    return new $nodeList(elements);
}
