import { DateTime } from 'luxon';
import {
    showDayData,
    showMonthData,
    showYearData,
    showAllData,
    showAllDataPerMonth,
    showRainDetails
} from './gss-rain';
import {
    initRainPanAndZoom,
    initRainPanAndZoomYearCompilation
} from './gss-nav';
const id = 'js-high-chart',
    now = DateTime.local();

export const routes = {
    '/regn/:year(\\d+)/:month/:day': rainDayDetails,
    '/regn/:year(\\d+)/:month': rainMonthDetails,
    '/regn/:year(\\d+)': rainYearDetails,
    '/regn': rainDetails,
    '/regn/:startYear-:endYear/:month': rainMonthCompilation,
    '/regn/:startYear-:endYear': rainYearCompilation
};
function rainDayDetails({ year, month, day }) {
    initRainPanAndZoom({ year, month, day });
    let requestedDate = DateTime.fromObject({ year, month, day });
    if (requestedDate.startOf('day') > now.startOf('day')) {
        let forecastHours =
            requestedDate.startOf('day').diff(now.startOf('day'), 'hours')
                .hours + 24;
        showRainDetails(id, forecastHours);
    } else {
        showDayData({
            period: year + '-' + month + '-' + day,
            id
        });
    }
}
function rainMonthDetails({ year, month }) {
    initRainPanAndZoom({ year, month });
    showMonthData({
        period: year + '-' + month,
        id
    });
}
function rainYearDetails({ year }) {
    initRainPanAndZoom({ year });
    showYearData({
        period: year,
        id
    });
}
function rainYearCompilation({ startYear, endYear }) {
    initRainPanAndZoomYearCompilation({ startYear, endYear });
    showAllData({
        period: {
            startYear,
            endYear
        },
        id
    });
}
function rainMonthCompilation({ startYear, endYear, month }) {
    initRainPanAndZoomYearCompilation({ startYear, endYear, month });
    showAllDataPerMonth({
        period: {
            startYear,
            endYear,
            month
        },
        id
    });
}
function rainDetails() {
    initRainPanAndZoom(DateTime.local());
    showRainDetails(id);
}
