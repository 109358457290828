import Highcharts from './gss-highcharts';
import * as core from './gss-core';
import {
    getTime,
    getCurrentDateHuge,
    getMonth,
    getDayAndDate,
    getMonthSubtitle,
    isYesterday,
    getYear
} from './gss-time';
import { DateTime } from 'luxon';
var highChartBaseOptions = {
        title: {
            text: 'Regn'
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            allowDecimals: false,
            min: 0,
            minRange: 10,
            title: {
                text: 'Regn (mm)'
            }
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'vertical',
            x: 0,
            y: 0
        }
    },
    highChartOptions = Object.assign({}, highChartBaseOptions, {
        credits: {
            text: 'Prognos från SMHI',
            href: 'https://www.smhi.se'
        },
        chart: {
            type: 'spline',
            panning: true,
            zoomType: 'x'
        },
        subtitle: {
            text: getCurrentDateHuge()
        },
        tooltip: {
            formatter: function () {
                return (
                    this.y + ' mm ' + getTime(new Date(this.x).toISOString())
                );
            }
        }
    }),
    highChartBaseStatOptions = Object.assign({}, highChartBaseOptions, {
        credits: {
            enabled: false
        },
        chart: {
            type: 'column',
            panning: true,
            zoomType: 'x',
            events: {}
        },
        plotOptions: {
            column: {
                borderWidth: 0,
                dataLabels: {
                    backgroundColor: '#373735',
                    color: '#E0E0E3',
                    padding: 0,
                    enabled: true,
                    y: -7,
                    format: '{point.y:.0f}'
                }
            }
        }
    }),
    highChartYearStatOptions = Object.assign({}, highChartBaseStatOptions, {
        tooltip: {
            formatter: function () {
                return (
                    Math.round(this.y) +
                    ' mm ' +
                    getMonth(new Date(this.x).toISOString())
                );
            }
        }
    }),
    highChartAllStatOptions = Object.assign({}, highChartBaseStatOptions, {
        tooltip: {
            formatter: function () {
                return (
                    Math.round(this.y) +
                    ' mm ' +
                    getYear(new Date(this.x).toISOString())
                );
            }
        }
    }),
    highChartMonthStatOptions = Object.assign({}, highChartBaseStatOptions, {
        tooltip: {
            formatter: function () {
                return (
                    Math.round(this.y) +
                    ' mm ' +
                    getDayAndDate(new Date(this.x).toISOString())
                );
            }
        }
    });
/**
 * Fetch rain forecast
 * @param {Object} offsetPoint
 * @param {String} offsetPoint.x
 * @param {Number} offsetPoint.y
 * @param {Number} forecastPeriod
 * @returns {Promise<Array>}
 */
async function fetchRainForecast(offsetPoint, forecastPeriod) {
    let data = await core.fetchGraphData('/forecast/rain/' + forecastPeriod),
        cumulativeSum = offsetPoint.y,
        cumulativeData = data.map(({ x, y }) => {
            cumulativeSum = (cumulativeSum * 10 + y * 10) / 10;
            return { x, y: cumulativeSum };
        });
    return [offsetPoint].concat(cumulativeData);
}
async function showYearData(options) {
    var yearOptions = Object.assign({}, highChartYearStatOptions, {
        subtitle: {
            text: options.period
        }
    });
    var chart = Highcharts.chart(options.id, yearOptions);
    //options.highChart.highcharts(yearOptions);
    let data = await core.fetchStatData({
        url: '/rain-radar/' + options.period,
        statOpt: core.statOptions.VALUE
    });
    chart.addSeries({
        name: 'Månadsnederbörd (mm)',
        index: 1,
        data: data
    });
}
async function showAllDataPerMonth({ period, id }) {
    let { startYear, endYear, month } = period,
        monthName = DateTime.fromObject({
            year: startYear,
            month: month,
            day: 1
        })
            .setLocale('sv')
            .toFormat('LLLL'),
        yearToProcess = [],
        yearOptions = Object.assign({}, highChartAllStatOptions, {
            subtitle: {
                text: monthName
            }
        }),
        chart = Highcharts.chart(id, yearOptions);
    for (let year = startYear; year <= endYear; year++) {
        yearToProcess.push(year);
    }
    let rainData = await Promise.all(
        yearToProcess.map((year) => {
            return core.fetchStatData({
                url: '/rain-radar/' + year,
                statOpt: core.statOptions.VALUE
            });
        })
    );
    chart.addSeries({
        name: 'Årsnederbörd (mm)',
        index: 1,
        data: rainData
            .map((data) => {
                return data.find((value) => {
                    let monthOfValue = parseInt(
                        new Date(value.x).toLocaleDateString().split('-')[1]
                    );
                    return monthOfValue === parseInt(month);
                });
            })
            .filter((value) => {
                return !!value;
            })
    });
}
async function showAllData({ period, id }) {
    let yearToProcess = [],
        yearOptions = Object.assign({}, highChartAllStatOptions, {
            subtitle: {
                text: 'Årsdata'
            }
        }),
        chart = Highcharts.chart(id, yearOptions);

    for (let year = period.startYear; year <= period.endYear; year++) {
        yearToProcess.push(year);
    }
    let rainData = await Promise.all(
        yearToProcess.map((year) => {
            return core.fetchStatData({
                url: '/rain-radar/' + year,
                statOpt: core.statOptions.VALUE
            });
        })
    );
    chart.addSeries({
        name: 'Årsnederbörd (mm)',
        index: 1,
        data: rainData.map((data) => {
            return data.reduce((acc, curr) => {
                return {
                    x: acc.x,
                    y: acc.y + curr.y
                };
            });
        })
    });
}
/**
 * Show rain data for a specific month
 *
 * @param {object} options
 * @param {string} options.id - id for the chart
 * @param {string} options.period - Date with format YYYY-MM
 */
async function showMonthData(options) {
    var monthOptions = Object.assign({}, highChartMonthStatOptions, {
            subtitle: {
                text: getMonthSubtitle(options.period)
            }
        }),
        highChart = Highcharts.chart(options.id, monthOptions);

    let data = await core.fetchStatData({
        url: '/rain-radar/' + options.period.replace('-', '/'),
        statOpt: core.statOptions.VALUE
    });
    highChart.addSeries({
        name: 'Dygnsnederbörd (mm)',
        index: 1,
        data: data.length > 0 ? data : false
    });
}
/**
 * Show rain data for a specific day
 *
 * @param {object} options
 * @param {string} options.id - id for the chart
 * @param {string} options.period - Date with format YYYY-MM-DD
 */
function showDayData(options) {
    var apiCalls = [],
        apiResult = [],
        highChart;
    highChart = Highcharts.chart(
        options.id,
        Object.assign({}, highChartOptions, {
            credits: {
                enabled: false
            },
            subtitle: {
                text: getDayAndDate(options.period)
            }
        })
    );
    function concat(data) {
        apiResult.push(data);
        if (apiCalls.length === apiResult.length) {
            var result;
            if (apiResult.length === 1) {
                result = apiResult.pop();
            } else {
                var result1 = apiResult.pop(),
                    result2 = apiResult.pop();
                if (result1.length === 0) {
                    result = result2;
                } else if (result2.length === 0) {
                    result = result1;
                } else {
                    var lastTimestamp1 = result1[result1.length - 1][0];
                    var lastTimestamp2 = result2[result2.length - 1][0];
                    if (lastTimestamp1 < lastTimestamp2) {
                        result2 = result2.filter(function (data) {
                            return data[0] > lastTimestamp1;
                        });
                        result = result1.concat(result2);
                    } else {
                        result1 = result1.filter(function (data) {
                            return data[0] > lastTimestamp2;
                        });
                        result = result2.concat(result1);
                    }
                }
            }
            if (result.length > 0) {
                result = [[result[0][0] - 300000, 0]].concat(result);
            }
            highChart.addSeries({
                data: result.length > 0 ? result : false,
                name: 'Observation',
                index: 1
            });
        }
    }
    async function last24h(callback) {
        let data = await core.fetchGraphData('/rain-radar/24h');
        callback(data);
    }
    async function periodData(callback) {
        let data = await core.fetchGraphData(
            '/rain-radar/' + options.period.replace(/-/gi, '/')
        );
        callback(data);
    }

    if (isYesterday(options.period)) {
        apiCalls.push(last24h);
    }
    apiCalls.push(periodData);
    apiCalls.forEach(function (fn) {
        fn.call(this, concat);
    });
}
async function fetchLast24hRainData() {
    let data = await core.fetchGraphData('/rain-radar/24h'),
        offset = { x: new Date().getTime(), y: 0 };
    if (data.length > 0) {
        // Adding start point 5 minutes earlier
        data = [{ x: data[0].x - 300000, y: 0 }].concat(data);
        // Adding point to forecast
        offset.y = data[data.length - 1].y;
        data = data.concat([offset]);
    }
    return {
        data,
        offset
    };
}
/**
 * Show latest 24h rain data and forecast
 *
 * @param {string} id - id of the element where chart should be rendered
 */
async function showRainDetails(id, forecastPeriod = 24) {
    let highChart = Highcharts.chart(id, highChartOptions),
        { data, offset } = await fetchLast24hRainData(),
        forecast = await fetchRainForecast(offset, forecastPeriod);
    highChart.addSeries(
        {
            data: data.length > 0 ? data : false,
            name: 'Observation',
            index: 1
        },
        false
    );
    highChart.addSeries(
        {
            color: 'red',
            data: forecast,
            name: 'Prognos',
            index: 2
        },
        true
    );
}
export {
    showRainDetails,
    showAllDataPerMonth,
    showAllData,
    showYearData,
    showMonthData,
    showDayData
};
