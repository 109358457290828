function padDate(date) {
    if (date.length > 1) {
        return date;
    }
    return date < 10 ? `0${date}` : `${date}`;
}
function getTitle(path) {
    const names = {
        oversikt: 'Gotland Weather Station',
        vind: 'Vind',
        temperatur: 'Temperatur',
        moln: 'Moln',
        regn: 'Regn',
        luftfuktighet: 'Luftfuktighet',
        aska: 'Åska',
        nu: 'Nu'
    };
    let [leading, main, year, month, day] = path.split('/');
    main = main || leading;
    return `GSS - ${names[main]} ${
        year
            ? month
                ? day
                    ? year + '-' + month + '-' + day
                    : year + '-' + month
                : year
            : 'prognos'
    }`;
}
function updateTitle(path) {
    document.title = getTitle(path);
}
module.exports = { padDate, updateTitle, getTitle };
