import { DateTime } from 'luxon';
import {
    showWindDetails,
    showWindObservationForDay,
    showWindObservationForMonth,
    showWindObservationForYear,
    showWindObservationForAllTime,
    showWindObservationForAllTimePerMonth
} from './gss-wind';
import {
    initWindPanAndZoom,
    initWindPanAndZoomYearCompilation
} from './gss-nav';
const highChartId = 'js-high-chart';
const now = DateTime.local();

export const routes = {
    '/vind/:year(\\d+)/:month/:day': windDayDetails,
    '/vind/:year(\\d+)/:month': windMonthDetails,
    '/vind/:year(\\d+)': windYearDetails,
    '/vind': windspeedDetails,
    '/vind/:startYear-:endYear/:month': windMonthCompilation,
    '/vind/:startYear-:endYear': windYearCompilation
};
function windspeedDetails() {
    initWindPanAndZoom(DateTime.local());
    showWindDetails(highChartId);
}
function windDayDetails({ year, month, day }) {
    initWindPanAndZoom({ year, month, day });
    let requestedDate = DateTime.fromObject({ year, month, day });
    if (requestedDate.startOf('day') > now.startOf('day')) {
        let forecastHours =
            requestedDate.startOf('day').diff(now.startOf('day'), 'hours')
                .hours + 24;
        showWindDetails(highChartId, forecastHours);
    } else {
        showWindObservationForDay(highChartId, year, month, day);
    }
}
function windMonthDetails({ year, month }) {
    initWindPanAndZoom({ year, month });
    showWindObservationForMonth(highChartId, year, month);
}
function windYearDetails({ year }) {
    initWindPanAndZoom({ year });
    showWindObservationForYear(highChartId, year);
}
function windYearCompilation({ startYear, endYear }) {
    initWindPanAndZoomYearCompilation({ startYear, endYear });
    showWindObservationForAllTime(highChartId, startYear, endYear);
}
function windMonthCompilation({ startYear, endYear, month }) {
    initWindPanAndZoomYearCompilation({ startYear, endYear, month });
    showWindObservationForAllTimePerMonth(
        highChartId,
        startYear,
        endYear,
        month
    );
}
