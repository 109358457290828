import query from '../client-script/query';
import { navigate, onEveryRoute } from './gss-router';
import { DateTime } from 'luxon';
import { padDate } from '../utils.js';
const minDate = DateTime.fromISO('2015-03-01T22:00:00.000+02:00');
export const initRainPanAndZoom = initPanAndZoom.bind(this, 'regn');
export const initRainPanAndZoomYearCompilation =
    initPanAndZoomYearCompilation.bind(this, 'regn');
export const initTempPanAndZoom = initPanAndZoom.bind(this, 'temperatur');
export const initTempPanAndZoomYearCompilation =
    initPanAndZoomYearCompilation.bind(this, 'temperatur');
export const initWindPanAndZoom = initPanAndZoom.bind(this, 'vind');
export const initWindPanAndZoomYearCompilation =
    initPanAndZoomYearCompilation.bind(this, 'vind');

function changeNavigation(selector) {
    let newNav = query(`.js-navigation ${selector}`);
    if (!newNav.hasClass('active-nav')) {
        let overview = query('.js-overview-view'),
            detailView = query('.js-detail-view');
        // Change highlighted item
        query('.active-nav').removeClass('active-nav');
        newNav.addClass('active-nav');
        if (!overview.hasClass('hidden')) {
            overview.addClass('hidden');
        }
        detailView.removeClass('hidden');
    }
}
function updatePanAndZoom(sensor, panPrev, zoomOut, zoomIn, panNext) {
    const sensorPath = `/${sensor}/`;
    if (zoomIn) {
        query('.js-zoom-in').attr('href', sensorPath + zoomIn);
    } else {
        query('.js-zoom-in').removeAttr('href');
    }
    if (zoomOut) {
        query('.js-zoom-out').attr('href', sensorPath + zoomOut);
    } else {
        query('.js-zoom-out').removeAttr('href');
    }
    if (panPrev) {
        query('.js-pan-prev').attr('href', sensorPath + panPrev);
    } else {
        query('.js-pan-prev').removeAttr('href');
    }
    if (panNext) {
        query('.js-pan-next').attr('href', sensorPath + panNext);
    } else {
        query('.js-pan-next').removeAttr('href');
    }
    query('.js-pan-and-zoom').removeClass('hidden');
}
function initPanAndZoomYearCompilation(sensor, { startYear, endYear, month }) {
    var zoomIn,
        zoomOut,
        panPrev,
        panNext,
        years = startYear + '-' + endYear,
        now = DateTime.local();
    if (month) {
        if (month !== '01') {
            panPrev = years + '/' + padDate(parseInt(month) - 1);
        }
        if (month !== '12') {
            panNext = years + '/' + padDate(parseInt(month) + 1);
        }
        zoomOut = years;
        zoomIn = endYear + '/' + padDate(month < now.month ? month : now.month);
    } else {
        zoomIn = now.year;
        panPrev = years + '/01';
        panNext = years + '/12';
    }
    updatePanAndZoom(sensor, panPrev, zoomOut, zoomIn, panNext);
}

function initPanAndZoom(sensor, { year, month, day }) {
    var zoomIn,
        zoomOut,
        panPrev,
        panNext,
        requestedDate,
        maxDate = DateTime.local().plus({ days: 10 });
    if (year && month && day) {
        requestedDate = DateTime.fromObject({
            year: year,
            month: month,
            day: day
        });
        zoomOut = requestedDate.toFormat('yyyy/MM');
        if (requestedDate.startOf('day') > minDate.startOf('day')) {
            panPrev = requestedDate.minus({ days: 1 }).toFormat('yyyy/MM/dd');
        }
        if (requestedDate.startOf('day') < maxDate.startOf('day')) {
            panNext = requestedDate.plus({ days: 1 }).toFormat('yyyy/MM/dd');
        }
    } else if (year && month) {
        requestedDate = DateTime.fromObject({
            year: year,
            month: month,
            day: 1
        });
        zoomOut = year;
        zoomIn = requestedDate.toFormat('yyyy/MM/dd');
        if (requestedDate.startOf('month') > minDate.startOf('month')) {
            panPrev = requestedDate.minus({ month: 1 }).toFormat('yyyy/MM');
        }
        if (requestedDate.startOf('month') < maxDate.startOf('month')) {
            panNext = requestedDate.plus({ month: 1 }).toFormat('yyyy/MM');
        }
    } else if (parseInt(year)) {
        requestedDate = DateTime.fromObject({
            year: year
        });
        zoomOut = minDate.year + '-' + DateTime.local().year;
        zoomIn = year + '/01';
        if (requestedDate.startOf('year') > minDate.startOf('year')) {
            panPrev = requestedDate.minus({ year: 1 }).year;
        }
        if (requestedDate.startOf('year') < maxDate.startOf('year')) {
            panNext = requestedDate.plus({ year: 1 }).year;
        }
    } else {
        zoomIn = maxDate.year;
    }
    updatePanAndZoom(sensor, panPrev, zoomOut, zoomIn, panNext);
}
export function hidePanAndZoom() {
    query('.js-pan-and-zoom').removeClass('hidden').addClass('hidden');
}

function routeChangeListener() {
    onEveryRoute((route) => {
        let selector;
        if (route.indexOf('regn') > -1) {
            selector = '.js-rain';
        } else if (route.indexOf('temperatur') > -1) {
            selector = '.js-temperature';
        } else if (route.indexOf('luftfuktighet') > -1) {
            selector = '.js-hum';
        } else if (route.indexOf('vind') > -1) {
            selector = '.js-wind';
        } else if (route.indexOf('aska') > -1) {
            selector = '.js-thunder';
        } else if (route.indexOf('moln') > -1) {
            selector = '.js-cloud';
        }
        selector && changeNavigation(selector);
    });
}
function buttonListener() {
    query('.js-overview').on('click', () => {
        navigate('/oversikt');
    });
    query('.js-temperature').on('click', () => {
        navigate('/temperatur');
    });
    query('.js-rain').on('click', () => {
        navigate('/regn');
    });
    query('.js-cloud').on('click', () => {
        navigate('/moln');
    });
    query('.js-hum').on('click', () => {
        navigate('/luftfuktighet');
    });
    query('.js-wind').on('click', () => {
        navigate('/vind');
    });
    query('.js-thunder').on('click', () => {
        navigate('/aska');
    });
    query('a.js-pan-and-zoom').on('click', (e) => {
        e && e.preventDefault();
        let href = e.currentTarget.getAttribute('href');
        if (href) {
            navigate(href);
        }
    });
}
function setUpListener() {
    buttonListener();
    routeChangeListener();
}
export { setUpListener };
