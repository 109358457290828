/*global se */
import { route, router, setBase, initDomListeners } from '@riotjs/route';
import { updateTitle } from '../utils.js';
import { routes as rainRoutes } from './gss-router-rain';
import { routes as tempRoutes } from './gss-router-temp';
import { routes as windRoutes } from './gss-router-wind';
import { routes as overviewRoutes } from './gss-router-overview';
let registeredRoutes = {};
function registerRoutes(routeObject) {
    for (const path in routeObject) {
        if (Object.hasOwnProperty.call(routeObject, path)) {
            const routeFn = routeObject[path];
            registeredRoutes[routeFn.name] = path;
            route(path).on.value((data) => {
                let { params } = data;
                for (const [key, value] of data.searchParams) {
                    params[key] = value;
                }
                routeFn(params);
            });
        }
    }
}
function init() {
    setBase(se.gss.conf.basePath ? se.gss.conf.basePath : '/');
    registerRoutes(overviewRoutes);
    registerRoutes(rainRoutes);
    registerRoutes(tempRoutes);
    registerRoutes(windRoutes);
    initDomListeners();
    let path = window.location.pathname;
    if (se.gss.conf.basePath) {
        path = path.slice(se.gss.conf.basePath.length);
    }
    router.push(path + window.location.search);
}
export function onEveryRoute(callback, context) {
    router.on.value((url) => {
        callback.call(context, url);
    });
}
export function navigate(fragment) {
    updateTitle(fragment);
    router.push(`${fragment}`);
}
init();
